
















































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import Methods from '@/components/methods'

@Component
export default class Home extends Mixins(Methods) {}
